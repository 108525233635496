<template>
<div>
	<v-row>
		<!-- Start Date -->
		<v-col cols="12" sm="3" md="3">
			<v-menu
				ref="dialog_date_1"
				v-model="modal.date_1"
				transition="scale-transition"
				persistent
				width="290px"
				>
					<template v-slot:activator="{ on }">
						<v-text-field
						v-model				="form.date_1"
						label				="Periode Awal"
						prepend-inner-icon	="event"
						background-color 	= "date"
						v-on				= "on"
						v-mask				="'####-##-##'"
						placeholder			= "YYYY-MM-DD"
						></v-text-field>
					</template>
					<v-date-picker v-model="form.date_1" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="modal.date_1 = false">Cancel</v-btn>
						<v-btn text color="primary" @click="$refs.dialog_date_1.save(form.date_1)">OK</v-btn>
					</v-date-picker>
				</v-menu>
		</v-col>
		<!-- End Date -->
		<v-col cols="12" sm="3" md="3">
			<v-menu
				ref="dialog_date_2"
				v-model="modal.date_2"
				transition="scale-transition"
				persistent
				width="290px"
				>
					<template v-slot:activator="{ on }">
						<v-text-field
						v-model				="form.date_2"
						label				="Periode Akhir"
						prepend-inner-icon	="event"
						background-color 	= "date"
						v-on				= "on"
						v-mask				="'####-##-##'"
						placeholder			= "YYYY-MM-DD"
						></v-text-field>
					</template>
					<v-date-picker v-model="form.date_2" :min = "form.date_1" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="modal.date_2 = false">Cancel</v-btn>
						<v-btn text color="primary" @click="$refs.dialog_date_2.save(form.date_1)">OK</v-btn>
					</v-date-picker>
				</v-menu>
		</v-col>
		<!-- Option PDF/Excel -->
		<v-col cols="12" sm="12" md="12" class="mt-n5">
			<v-radio-group v-model="form.output">
				<v-radio
					v-for="row in form.outputdata"
					:key="row.id"
					:value="row.id"
					:label="row.id">
				</v-radio>
			</v-radio-group>
		</v-col>
		<v-col cols="12" sm="12" md="12">
			<v-btn @click="Print" color="primary">Print</v-btn>
		</v-col>
	</v-row>
	<v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
        <v-card>
            <v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
            	<v-spacer></v-spacer>
            	<v-btn id="dialog" color="primary"
            		   :disabled   = "confirm.text === 'Ok' ? false:true"
      				   @click      = "ProcessPrint">{{confirm.text}}</v-btn>
                <v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
	export default {
	name:'rpt-actuary',
  		data: () => ({
			modal: {
				date_1: false,
				date_2: false,
			},
			confirm: {
				dialog: false,
				title: '',
				text: 'Ok'
			},
			form: {
				date_1: '',
				date_2: '',
				output: 'PDF',
				outputdata: [
					{id:'PDF'},
					{id:'EXCEL'}
				]
			}
		}),
		methods: {
			Print () {
				this.confirm.dialog = true
				this.confirm.title  = 'Print '+this.form.output
			},
			ProcessPrint () {
				this.confirm.text = 'Loading...'
			}
		}
	}
</script>